<template lang="pug">
  .timetable
    section.hero
      .hero__container
        h1.hero__title.title
          | Розклад
          |
          span занять
        p.hero__desc Детальна інформація про час тренувань груп для всіх напрямків.
        h2.hero__sub-title Розклад
    section.schedule
      .schedule__inner
        .schedule__container.schedule__container--big
          p.schedule__years 2023-2024
          vue-slick-carousel.schedule__day-slider.day-slider(
            ref="c1"
            :as-nav-for="c2"
            v-bind="settingsDaySlider"
          )
            .day-slider__slide(
              v-for="(day, index) in days"
              :key="index"
            )
              p {{ day.title }}

      .schedule__container
        vue-slick-carousel.schedule__timetable-slider.timetable-slider(
          ref="c2"
          :as-nav-for="c1"
          v-bind="settingsЕimetableІlider"
        )
          .timetable-slider__slide(
            v-for="(category, categoryIndex) in categoriesData"
            :key="categoryIndex"
          )
            vue-tabs.schedule__tabs
              v-tab.schedule__tab(
                v-for="(slide, slideIndex) in category.slides"
                :key="slideIndex"
                :title="slide.title"
              )
                ul.schedule__list
                  li.schedule__item(
                    v-for="(tab, tabIndex) in slide.tabs"
                    :key="tabIndex"
                    :class="tab.isBlack ? 'schedule__item--black' : 'schedule__item--white'"
                  )
                    .schedule__date {{ tab.date }}
                    .schedule__time {{ tab.time }}
                    .schedule__table
                      table
                        tbody
                          tr
                            td Напрям:
                            td {{ tab.direction }}
                          tr
                            td Тренер:
                            td {{ tab.trainer }}
                    a.schedule__link.link(href="tel:+380687835335") Запис

          .timetable-slider__slide.timetable-slider__slide--sunday
            img(
              :src="Calendar"
              alt="calendar"
            )
            p.timetable-slider__text-black Вибачте, але сьогодні немає тренувань.
            p Час відпочити та набратись сил для нового тижня.
</template>

<script>
// constants
import { categoriesData } from '@/configs/constants'
import Calendar from '@/assets/images/timetable-page/calendar.png'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { VueTabs, VTab } from 'vue-nav-tabs'
export default {
  name: 'Timetable',
  components: {
    VueSlickCarousel,
    VueTabs,
    VTab
  },
  data () {
    return {
      days: [
        { title: 'Понеділок' },
        { title: 'Вівторок' },
        { title: 'Середа' },
        { title: 'Четвер' },
        { title: 'П\'ятниця' },
        { title: 'Субота' },
        { title: 'Неділя' }
      ],
      categoriesData: categoriesData,
      Calendar,
      settingsDaySlider: {
        slidesToShow: 7,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 998,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      },
      settingsЕimetableІlider: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        fade: true,
        adaptiveHeight: true
      },
      c1: undefined,
      c2: undefined
    }
  },
  mounted () {
    this.c1 = this.$refs.c1
    this.c2 = this.$refs.c2
  }
}
</script>
